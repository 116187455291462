

.ddWrapper{
    position: relative;
    width: 222px;
    font-size: 1.6rem;
    user-select: none;
    border: 1px solid #ccc;
    padding: 4px 10px;
    border-radius: 5px;
    background: #fff;
    margin-bottom: 16px;
    height: 34px;
  }
  
  .ddWrapper button {
    overflow: visible;
    width: 100%;
    border: none;
    font: inherit;
    line-height: normal;
    color: inherit;
    text-align: inherit;
    -webkit-appearance: none;
  }
  
  
  
  .ddHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border-radius: 3px;
    background: transparent;
    line-height: 38px;
    cursor: default;
    cursor: pointer;
  }
  
  .ddHeader span {
    display: flex;
  }

  .ddHeader span img {
      width: 12px;
  }
  
  .ddHeaderTitle {
    margin: 2px 0px;
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 20px;
    color: rgb(51, 51, 51);
    overflow: hidden;
    text-overflow: ellipsis;
    width: 75%;
    white-space: nowrap;
    margin-right: 10px;
  }
  .angleDown{
    margin-right: 20px;
    color: black;
  }
  
  .ddList {
    border: 1px solid rgb(223, 223, 223);
    border-top: none;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: 0 2px 5px -1px rgb(232, 232, 232);
    background-color: white;
    font-weight: 700;
    text-align: left;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: column;
  }
  
  .ddScrollList{
    overflow-y: auto;
    max-height: 215px;
    padding: 15px 0px;
    display: flex;
    flex-direction: column;
  }
  
  .ddListItem {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    font-size: 0.875rem;
    line-height: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: default;
    cursor: pointer;
    border: none;
    background-color:transparent;
    padding: 10px;
    font-weight: 300;
    display: flex;
    align-items: center;
    font-family: 'Roboto','Helvetica','Arial',sans-serif;
    display: flex;
    justify-content: space-between;
  }
  .noResult{
    font-weight: normal;
    cursor: default;
    font-size: 14px;
  }
  .noResult:hover{
      background-color: transparent;
      color: black;
  }
  .ddListItem:hover{
    background-color: #f5f5f5;
    color: #4F4F4F;
  }
  .ddListItem:hover span > svg > path{
      fill: white ;
  }
  
  .ddListSearchBar {
    width: 95%;
    margin: 10px auto 0;
    height: 40px;
    padding: 0 10px;
    border: none;
    border: 1px solid;
    font-size: inherit;
    padding-bottom: 0 !important;
  }
  
  .ddListSearchBar::placeholder{
      color: rgb(200, 200, 200);
  }
  
  .ddListSearchBar.searchable{
    overflow-y: hidden;
    padding: 0;
  }