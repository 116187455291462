
@font-face {
  font-family: "BentonSans";
  src: url("./assets/fonts/BentonSans-Medium.woff") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "BentonSans";
  src: url("./assets/fonts/BentonSans-Regular.woff") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lato Black";
  src: url("./assets/fonts/lato-black-webfont.woff") format("truetype");
  font-weight: normal;
  font-style: normal;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*:focus {
  outline: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.audience-model-open {
  overflow: hidden;
}

.unifiedRow {
  background-color: #fff !important;
  font-size: .875rem !important;
  padding: 0px 20px 0 0 !important;
  font-weight: 300 !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}

.unifiedRowEven {
  background-color: #f5f5f5 !important;
}

.Mui-focused {
  outline: none !important;
  border: none;
}

.ag-cell.highlight-cell {
  background-color: lightyellow;
  border: 1px solid darkgoldenrod;
}
