
 .checkbox {
    position: absolute;
    opacity: 0;
 }
 
 .checkbox + label {
    position: relative;
    padding: 0;
    cursor: pointer;
 }
 
 .checkbox + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: 1px solid #ccc;
    background: white;
 }
 
 .checkbox:checked + label:after {
    content: '';
    position: absolute;
    background: url('../../../assets/images/checkMark.png');
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    left: 3px;
    top: -3px;
 }